import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const DATA_API_URL = environment.baseUrl;

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json',
//     'x-access-token': JSON.parse(localStorage.getItem('id_token'))
//   })
// };



@Injectable({
  providedIn: 'root'
})
export class DiagnoRegisterService {
  constructor(private http: HttpClient) { }
  private getAuthHttpOptionsAfterAuth() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': JSON.parse(localStorage.getItem('id_token'))
      })
    };
    return httpOptions;
  }
  //New services
  private getDiagCentreDetailsUrl = DATA_API_URL + '/diagnostic/GetDiagCentreDetails';
  private getBookingStatusMasterUrl = DATA_API_URL + '/diagnostic/GetDiagStatusMaster';

  private diagnoSaveProfileUrl = DATA_API_URL + '/diagnostic/UpdateDiagProfile';
  private diagnoSaveBankDetailsUrl = DATA_API_URL + '/diagnostic/UpdateDiagBankDetails';
  private diagnoSaveServiceAreaUrl = DATA_API_URL + '/diagnostic/UpdateDiag_ServiceArea';

  private diagnoUploadBannerUrl = DATA_API_URL + '/diagnostic/UploadDiagCentre';
  private diagnoUpdateBannerInfoUrl = DATA_API_URL + '/diagnostic/UploadDiagBanner';
  private diagUploadTestResultUrl = DATA_API_URL + '/diagnostic/UploadTestResult';

  private diagnoGetServiceRateCardUrl = DATA_API_URL + '/diagnostic/GetDiagServiceRate';
  private diagnoSaveServiceRateCardUrl = DATA_API_URL + '/diagnostic/UpdateDiagServiceRate';

  private getDiagCentreBookingDetailsUrl = DATA_API_URL + '/diagnostic/GetDiagCentreBooking';
  private updateBookingStatusUrl = DATA_API_URL + '/diagnostic/SetDiagStatus';

  private getSetPhebloDetailsUrl = DATA_API_URL + '/diagnostic/GetSetPheblo';

  private getTestDetailbyCentreUrl = DATA_API_URL + '/diagnostic/GetTestDetailbyCentre';
  private updateTestDetailbyCentreUrl = DATA_API_URL + '/diagnostic/SetTestDetailbyCentre';

  private getDiagCentrePaymentDetailsUrl = DATA_API_URL + "/diagnostic/GetDiagCentrePayment";

  private sendNotificationUrl = DATA_API_URL + "/diagnostic/SendNotDiagFromWeb";

  private getSetPincodeUrl = DATA_API_URL + "/diagnostic/GetSetPincode";
  private getAllTestUrl = DATA_API_URL + "/diagnostic/GetAllTest";

  private getDiagnostic_TestidUrl = DATA_API_URL + "/diagnostic/GetDiagnosticByMultipleTestid";

  private getDiag_SlotUrl = DATA_API_URL + "/diagnostic/GetDiag_Slot"
  private diagBookingUrl = DATA_API_URL + "/diagnostic/DiagBooking"

  getCentreDetails(): Observable<any> {
    return this.http.post<any>(this.getDiagCentreDetailsUrl, null, this.getAuthHttpOptionsAfterAuth());
  }

  getBookingStatusMaster() {
    return this.http.post<any>(this.getBookingStatusMasterUrl, null, this.getAuthHttpOptionsAfterAuth());
  }

  saveProfile(obj: any): Observable<any> {
    console.log(obj);
    return this.http.post<any>(this.diagnoSaveProfileUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  saveBankDetails(obj: any): Observable<any> {
    return this.http.post<any>(this.diagnoSaveBankDetailsUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  UploadBanner(obj: any): Observable<any> {
    return this.http.post<any>(this.diagnoUploadBannerUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  UpdateBannerInfo(obj: any): Observable<any> {
    return this.http.post<any>(this.diagnoUpdateBannerInfoUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  UploadTestResult(obj: any): Observable<any> {
    return this.http.post<any>(this.diagUploadTestResultUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  saveServiceArea(obj: any): Observable<any> {
    return this.http.post<any>(this.diagnoSaveServiceAreaUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }
  //Service Rate card
  getServiceRateCard(obj: any): Observable<any> {
    return this.http.post<any>(this.diagnoGetServiceRateCardUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  updateServiceRateCard(obj: any): Observable<any> {
    return this.http.post<any>(this.diagnoSaveServiceRateCardUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }
  //Booking details
  getDiagCentreBookingDetails() {
    return this.http.post<any>(this.getDiagCentreBookingDetailsUrl, null, this.getAuthHttpOptionsAfterAuth());
  }

  updateBookingStatus(obj: any): Observable<any> {
    return this.http.post<any>(this.updateBookingStatusUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  //Get set pheblo detail
  getSetPhebloDetailsDetails(obj: any): Observable<any> {
    return this.http.post<any>(this.getSetPhebloDetailsUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  //Test Details
  getTestDetailbyCentre(obj: any) {
    return this.http.post<any>(this.getTestDetailbyCentreUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  updateTestDetailbyCentre(obj: any): Observable<any> {
    return this.http.post<any>(this.updateTestDetailbyCentreUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  //Centre Payment details
  getDiagCentrePaymentDetails() {
    return this.http.post<any>(this.getDiagCentrePaymentDetailsUrl, null, this.getAuthHttpOptionsAfterAuth());
  }

  SendDiagNotification(obj: any): Observable<any> {
    return this.http.post<any>(this.sendNotificationUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  GetSetPincode(obj: any): Observable<any> {
    return this.http.post<any>(this.getSetPincodeUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  GetAllTest(obj: any): Observable<any> {
    return this.http.post<any>(this.getAllTestUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  GetDiagnostic_Testid(obj: any): Observable<any> {
    return this.http.post<any>(this.getDiagnostic_TestidUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  GetDiag_Slot(obj: any): Observable<any> {
    return this.http.post<any>(this.getDiag_SlotUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  DiagBooking(obj: any): Observable<any> {
    return this.http.post<any>(this.diagBookingUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  //*************************************************************************************** */
  //Old Code
  private DiagnosticRegUrl = 'http://localhost:3006/diagnostic/UpdateDiagCentreRegis';
  private getDiagnoIdUrl = 'http://localhost:3006/diagnostic/GetdiagnoCenter';
  private diagnosticBookUrl = 'http://localhost:3006/diagnostic/userDiagBookingHistory';
  private diagnoRejectAcceptUrl = 'http://localhost:3006/diagnostic/AcceeptRejectByDiagnostics';





  getDiagnoId(obj: any): Observable<any> {
    return this.http.post<any>(this.getDiagnoIdUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  DiagnoRegis(obj: any): Observable<any> {
    return this.http.post<any>(this.DiagnosticRegUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  diagnosticsBooking(obj: any): Observable<any> {
    return this.http.post<any>(this.diagnosticBookUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }

  diagnoacceptreject(obj: any): Observable<any> {
    return this.http.post<any>(this.diagnoRejectAcceptUrl, obj, this.getAuthHttpOptionsAfterAuth());
  }


}


import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { DiagnosticProfileModule } from 'src/app/Diagnostics/diagnostic-profile/diagnostic-profile.module';
import { DiagnosticRegistrationModule } from 'src/app/Diagnostics/diagnostic-registration/diagnostic-registration.module';
import { DiagnosticsBookingModule } from 'src/app/Diagnostics/diagnostics-booking/diagnostics-booking.module';
import { DiagnosticCentrePaymentModule } from './Diagnostics/diagnostic-centre-payment/diagnostic-centre-payment.module';

import { CurrentRidesModule } from './Ambulance/CurrentRides/current-rides.module';
import { RideHistoryModule } from './Ambulance/RideHistory/ride-history.module';
import { RideRequestedModule } from './Ambulance/RideRequested/ride-requested.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { ToastrModule } from 'ngx-toastr';
import { AdvanceElementsModule } from './theme/forms/advance-elements/advance-elements.module';
import { PharmacyModule } from './Pharmacy/pharmacy.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent,
    //SpinnerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    DiagnosticProfileModule,
    DiagnosticsBookingModule,
    DiagnosticRegistrationModule,
    DiagnosticCentrePaymentModule,
    PharmacyModule,
    AdvanceElementsModule,
    CurrentRidesModule,
    RideHistoryModule,
    RideRequestedModule,
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [MenuItems],
  bootstrap: [AppComponent]
})
export class AppModule { }

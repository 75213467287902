import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2  } from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from "ngx-spinner"; 


import { DiagnoRegisterService } from 'src/app/Service/diagno-register.service';
import {
  NgbDatepicker, 
  NgbInputDatepicker, 
  NgbDateStruct, 
  NgbCalendar, 
  NgbDateAdapter,
  NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {NgModel} from "@angular/forms";

import {Subscription} from 'rxjs';

const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
!one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
  ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
!one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
  ? false : one.day > two.day : one.month > two.month : one.year > two.year;

@Component({
  selector: 'app-diagnostic-centre-payment',
  templateUrl: './diagnostic-centre-payment.component.html',
  styleUrls: ['./diagnostic-centre-payment.component.scss']
})
export class DiagnosticCentrePaymentComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  

  public data: any;
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';
  
  //For test result pop up
  rows = [];
  expanded = {};
  timeout: any;
  rowsFilter = [];
  tempFilter = [];
  public statusFiltervalue: number = 0;

  @Input('modalDefault') modalDefault: any;

  //below code for date
  startDate: NgbDateStruct;
    maxDate: NgbDateStruct;
    minDate: NgbDateStruct;
    hoveredDate: NgbDateStruct;
    fromDate: any;
    toDate: any;
    filterFromDate: any;
    filterToDate: any;
    model: any;
    private _subscription: Subscription;
    private _selectSubscription: Subscription;
    @ViewChild("d") input: NgbInputDatepicker;
    @ViewChild(NgModel) datePick: NgModel;
    @ViewChild('myRangeInput') myRangeInput: ElementRef;

    isHovered = date => 
    this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate)
    isInside = date => after(date, this.fromDate) && before(date, this.toDate);
    isFrom = date => equals(date, this.fromDate);
    isTo = date => equals(date, this.toDate);


  constructor(private diagnoRegisterService:DiagnoRegisterService
    ,element: ElementRef, private renderer: Renderer2, private _parserFormatter: NgbDateParserFormatter
    , private spinner: NgxSpinnerService
       ) { }

  ngOnInit() {
    this.spinner.show();
    this.startDate = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    this.maxDate = { year: now.getFullYear() + 1, month: now.getMonth() + 1, day: now.getDate()};
    this.minDate = {year: now.getFullYear() - 1, month: now.getMonth() + 1, day: now.getDate()};

    this.diagnoRegisterService.getDiagCentrePaymentDetails()
    .subscribe((data) => {
      this.data = data.data[0];
    }
    ,err=>console.log(err)
    ,() => this.spinner.hide()
    );
  }

    onDateSelection(date: NgbDateStruct) {
   // let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day)
    let parsed = '';
    if (!this.fromDate && !this.toDate) {
        this.fromDate =  date;
        this.filterFromDate = new Date(date.year, date.month - 1, date.day);
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
        this.toDate = date;
        this.filterToDate = new Date(date.year, date.month - 1, date.day)
        // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
        this.input.close();
    } else {
        this.toDate = null;
        this.fromDate = date;
        this.filterFromDate = new Date(date.year, date.month - 1, date.day);
    }
    if(this.fromDate) {
      parsed += this._parserFormatter.format(this.fromDate);
    }
    if(this.toDate) {
      parsed += ' - ' + this._parserFormatter.format(this.toDate);
    }
   
    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);
    
    
  }

  setStatusFilter(statusId: number) {
    this.statusFiltervalue = statusId;
  }

}

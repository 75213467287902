export class DiagnoRegister {
    DiagCentreId: Number;
    UserId: Number;
    DiagCentreName: String;
    DiagCentreDisplayName: String;
    Addreess: String;
    Owner_Name: String;
    Owner_Ph: String;
    Owner_Email: String;
    Centre_Email: String;
    Centre_Ph: String;
    Centre_Type: String;
    Centre_Regis_number: String;
    Account_Number: String;
    IFSC: String;
    GST_Number: String;
    Bank_Name: String;
    PAN_Number: String;
    IsCompliance: String;

}
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder,NgForm } from '@angular/forms';
import { DiagnoRegisterService } from 'src/app/Service/diagno-register.service';
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


const DATA_API_URL = environment.baseUrl;

const URL = DATA_API_URL +'/UploadDiagCentre';

@Component({
  selector: 'app-diagnostic-profile',
  templateUrl: './diagnostic-profile.component.html',
 // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./diagnostic-profile.component.scss']
})
export class DiagnosticProfileComponent implements OnInit,OnDestroy {
  mySubscription: any;
  DiagCentreId: number;
  UserId: number;
  Profile: FormGroup;
  Banking: FormGroup;
  ServiceArea: any[] = [];
  NewPincode: number;
  NewWalkIn: boolean = false;
  NewHomecollection: boolean = false;
  submitted: boolean = false;
  fileToUpload: File = null;
  public uploader:FileUploader;
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;
  response:string;
  bannerUrl: string = '';

  imageFile: {link: string, file: any, name: string};
  
  constructor(private formBuilder: FormBuilder
      , private diagnoRegisterService:DiagnoRegisterService
      , private toastr: ToastrService
      ,private router: Router
      , private spinner: NgxSpinnerService
      )
    { 
      this.router.routeReuseStrategy.shouldReuseRoute = function(){
        return false;

      };

      this.mySubscription = this.router.events.subscribe(event => {
          if(event instanceof NavigationEnd){
            this.router.navigated = false;
          }
      });

     
      this.uploader = new FileUploader({
        url: URL
      });

     this.hasBaseDropZoneOver = false;
     this.hasAnotherDropZoneOver = false;
 
    this.response = '';
    //this.uploader.response.subscribe( res => this.response = res );
    this.uploader.response
      .subscribe(res => {
        this.response = res;
        let resJson: any = JSON.parse(res);
        if (resJson.originalname === this.uploader.queue[0].file.name) {
          this.toastr.success('Successfully ' + 'Banner uploaded!', 'Success');
          //update file name in DB
          this.UpdateBannerInfo(this.uploader.queue[0].file.name);
        } else {
          this.toastr.error(this.response, 'Opps!');
        }
      },
      err => console.log(err),
      ()=>{ console.log("file uploaded.....")}
    );
  }

  ngOnDestroy(){
    if(this.mySubscription){
      this.mySubscription.unsubscribe();
    }
  }

  allowOne(){
    this.uploader.clearQueue();
  }

   UpdateBannerInfo(filename:string){
    let obj = {
       "filename" : filename,
       "UserId": this.UserId
      }

    this.diagnoRegisterService.UpdateBannerInfo(obj)
    .subscribe(data => { 
      if(data.message==="success"){
        this.toastr.success('Successfully ' + 'Banner Info uploaded!', 'Success');
        this.allowOne();
      }
      else{
        this.toastr.error(data.message, 'Opps!');
      }
    });
  }

  ngOnInit() {
    
    console.log(localStorage.getItem('id_token'));
    this.loadCenterDetails();

    this.Profile = this.formBuilder.group({
      DiagCentreId:[''],
      DiagCentreName: ['', Validators.required],
      DiagCentreDisplayName: ['', Validators.required],
      Centre_Type: ['', Validators.required],
      Addreess: ['', Validators.required],
      Owner_Name: ['', Validators.required],
      Owner_Email: ['', [Validators.required,Validators.email]],
      Centre_Email: ['', [Validators.required,Validators.email]],
      Centre_Regis_number: ['', Validators.required],
      Owner_Ph: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Centre_Ph: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      Banner:['']
    });

    this.Banking = this.formBuilder.group({
      Account_Number: ['', Validators.required],
      IFSC: ['', Validators.required],
      GST_Number: ['', Validators.required],
      Bank_Name: ['', Validators.required],
      PAN_Number: ['', Validators.required],
    });

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    }
  }

  get fProfile() { return this.Profile.controls; }
  get fBanking() { return this.Banking.controls; }
 
	loadCenterDetails() {
    this.spinner.show();
   this.diagnoRegisterService.getCentreDetails()
   .subscribe(data => {
    console.log(data);
    if(data.data[0].length===0){}else{
      this.DiagCentreId = data.data[0][0].DiagCentreId;
      this.UserId = data.data[0][0].UserId;
      this.Profile.controls["DiagCentreId"].setValue(data.data[0][0].DiagCentreId);
      this.Profile.controls["DiagCentreName"].setValue(data.data[0][0].DiagCentreName);
      this.Profile.controls["DiagCentreDisplayName"].setValue(data.data[0][0].DiagCentreDisplayName);
      this.Profile.controls["Centre_Type"].setValue(data.data[0][0].Centre_Type);
      this.Profile.controls["Addreess"].setValue(data.data[0][0].Addreess);
      this.Profile.controls["Owner_Name"].setValue(data.data[0][0].Owner_Name);
      this.Profile.controls["Owner_Ph"].setValue(data.data[0][0].Owner_Ph);
      this.Profile.controls["Owner_Email"].setValue(data.data[0][0].Owner_Email);
      this.Profile.controls["Centre_Email"].setValue(data.data[0][0].Centre_Email);
      this.Profile.controls["Centre_Ph"].setValue(data.data[0][0].Centre_Ph);
      this.Profile.controls["Centre_Regis_number"].setValue(data.data[0][0].Centre_Regis_number);
      this.Profile.controls["Banner"].setValue(JSON.stringify(data.data[0][0].Banner));

      this.Banking.controls["Account_Number"].setValue(data.data[0][0].Account_Number);
      this.Banking.controls["IFSC"].setValue(data.data[0][0].IFSC);
      this.Banking.controls["GST_Number"].setValue(data.data[0][0].GST_Number);
      this.Banking.controls["Bank_Name"].setValue(data.data[0][0].Bank_Name);
      this.Banking.controls["PAN_Number"].setValue(data.data[0][0].PAN_Number);
      
      this.bannerUrl = data.data[0][0].Banner; 
      console.log("Banner is:" + data.data[0][0].Banner);

      this.ServiceArea = data.data[1];


      console.log(this.Profile.value);
    }
   }
   ,err=>console.log(err)
   ,() => this.spinner.hide()
   )
  }

 handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);// element.target.files;
  }

  UpdateProfile(){
    console.log(this.Profile.controls["Banner"].value);
    
    let obj = {
      "DiagCentreId": this.DiagCentreId===0?null:this.DiagCentreId,
      //"UserId": this.UserId,
      "DiagCentreName": this.Profile.controls["DiagCentreName"].value,
      "DiagCentreDisplayName": this.Profile.controls["DiagCentreDisplayName"].value,
      "Addreess": this.Profile.controls["Addreess"].value,
      "Owner_Name": this.Profile.controls["Owner_Name"].value,
      "Owner_Ph": this.Profile.controls["Owner_Ph"].value,
      "Owner_Email": this.Profile.controls["Owner_Email"].value,
      "Centre_Email": this.Profile.controls["Centre_Email"].value,
      "Centre_Ph": this.Profile.controls["Centre_Ph"].value,
      "Centre_Type": this.Profile.controls["Centre_Type"].value,
      "Centre_Regis_number": this.Profile.controls["Centre_Regis_number"].value,
     // "Banner": this.Profile.controls["Banner"].value
      }
     // console.log(this.DiagCentreId);
    this.diagnoRegisterService.saveProfile(obj)
    .subscribe(data => {
      if (data.success) {
        this.toastr.success('Successfully ' + 'Profile updated!', 'Success');
        if(this.DiagCentreId===0){
          // this.router.navigateByUrl('/register new diagnostics/diagnostic-profile', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['diagnostic-profile']);
          // }); 
          this.router.navigate(['/register new diagnostics/diagnostic-profile']);
        }
        this.loadCenterDetails();
      } else {
        this.toastr.error(data.message, 'Opps!');
      }
    });
  }

  UpdateBankingDetails(){
    let obj = {
      "DiagCentreId": this.DiagCentreId,
      "Account_Number": this.Banking.controls["Account_Number"].value,
      "IFSC": this.Banking.controls["IFSC"].value,
      "GST_Number": this.Banking.controls["GST_Number"].value,
      "Bank_Name": this.Banking.controls["Bank_Name"].value,
      "PAN_Number": this.Banking.controls["PAN_Number"].value
      }
  
    this.diagnoRegisterService.saveBankDetails(obj)
    .subscribe(data => {
      if (data.success) {
        this.toastr.success('Successfully ' + 'Bank details updated!', 'Success');
        this.loadCenterDetails();
      } else {
        this.toastr.error(data.message, 'Opps!');
      }
    });
  }

  UpdateServiceAreaDetails() {
    if (((document.getElementById('txtPincode') as HTMLInputElement).value) != "") {
      this.NewPincode = Number((document.getElementById('txtPincode') as HTMLInputElement).value);
     
      if (this.NewHomecollection === false && this.NewWalkIn === false) {
        this.toastr.error("Please select service type!");
      }
      else {
        
        let obj = {
          "DiagCentreId": this.DiagCentreId,
          "Pincode": this.NewPincode ,
          "IsWalkin": this.NewWalkIn ,
          "IsHomeCollection": this.NewHomecollection 
        }
        console.log(obj);
        this.diagnoRegisterService.saveServiceArea(obj)
          .subscribe(data => {
            if (data.success) {
              this.toastr.success('Successfully ' + 'changes have been saved!', 'Success');
              this.loadCenterDetails();
            } else {
              this.toastr.error(data.message, 'Opps!');
            }
          });
      }
    }
    else{
      this.toastr.error("Please enter pin code!");
    }

  }

  DeleteServiceArea(ServiceAreaId: number, pincode: string){
    let obj = {
      "DiagCentreId": this.DiagCentreId,
      "Pincode": pincode ,
      "DELETE": 1
    }
    
    this.diagnoRegisterService.saveServiceArea(obj)
      .subscribe(data => {
        if (data.success) {
          this.toastr.success('Successfully ' + 'Service area deleted!', 'Success');
          this.loadCenterDetails();
        } else {
          this.toastr.error(data.message, 'Opps!');
        }
      });
  }
  // Image Preview
  imagesPreview(event) {
    
    if (event.target.files && event.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (_event: any) => {
            this.imageFile = {
                link: _event.target.result,
                file: event.srcElement.files[0],
                name: event.srcElement.files[0].name
            };
        };
        reader.readAsDataURL(event.target.files[0]);
    }
    
}
 
}


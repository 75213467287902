import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { SwPush } from '@angular/service-worker'
import { NotificationService } from './Service/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Hanumaan';

  constructor(private router: Router,
   ) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // this.requestSubscription();
  }  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DiagnosticsCentrePaymentRoutingModule } from './diagnostic-centre-payment-routing.module';
import {DiagnosticCentrePaymentComponent} from './diagnostic-centre-payment.component';
import {HttpModule} from '@angular/http';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
//import {DataFilterPipe} from '../../shared/elements/data-filter.pipe';
import {DataTableModule} from 'angular2-datatable';
import { SearchByNamePipe } from './search-by-name.pipe';
import { SearchByStatusPipe } from './search-by-status.pipe';
import { SearchByDatePipe } from './search-by-date.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,ReactiveFormsModule,
    DiagnosticsCentrePaymentRoutingModule,
    DataTableModule,
    HttpModule,
    NgxDatatableModule,
    Ng2SmartTableModule,
    NgxSpinnerModule
  ],
  declarations: [DiagnosticCentrePaymentComponent, SearchByNamePipe, SearchByStatusPipe, SearchByDatePipe
  ]
})
export class DiagnosticCentrePaymentModule { }

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({
  name: 'searchByName'
})
export class SearchByNamePipe implements PipeTransform {

  transform(array: any[], query: string): any {
    if (query) {
        return _.filter(array, row=>row.Patient_Name.toUpperCase().indexOf(query.toUpperCase()) > -1);
    }
    return array;
  }

}

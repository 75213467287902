import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import {NgModel} from "@angular/forms";
import {DatatableComponent} from '@swimlane/ngx-datatable';
import { NgxSpinnerService } from "ngx-spinner"; 

@Component({
  selector: 'app-ride-history',
  templateUrl: './ride-history.component.html',
  styleUrls: ['./ride-history.component.scss']
})
export class RideHistoryComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  

  public data: any;
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortBy = '';
  public sortOrder = 'desc';
  
  //For test result pop up
  rows = [];
  expanded = {};
  timeout: any;
  rowsFilter = [];
  tempFilter = [];
  public statusFiltervalue: number = 0;
  constructor() { }

  ngOnInit() {
  }

}

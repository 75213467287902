import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wizard-basic',
  templateUrl: './wizard-basic.component.html',
  styleUrls: ['./wizard-basic.component.scss']
})
export class WizardBasicComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    data: {
      status: false
    },
    children: [
    {
      path: 'ListOrders',
      loadChildren: 'src/app/Pharmacy/search/search.module#PharmacySearchModules',
      data: {
        title: 'Pharmacy Search',
        icon: 'icon-layout-cta-right',
        status: true
      }
    }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PharmacyRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { DiagnoRegisterService } from 'src/app/Service/diagno-register.service';
import { DiagnoRegister } from 'src/app/models/DiagnoRegister';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-diagnostic-registration',
  templateUrl: './diagnostic-registration.component.html',
  styleUrls: ['./diagnostic-registration.component.scss']
})
export class DiagnosticRegistrationComponent implements OnInit {

  myForm: FormGroup;
  submitted: boolean;
  DiagnoRegisterArr: DiagnoRegister[] = Array();
  diagnoRegister = new DiagnoRegister();


  constructor(private formBuilder: FormBuilder, private _dignoRegister: DiagnoRegisterService, private toastr: ToastrService) {

  }

  ngOnInit() {
    // this.diagnoRegister.UserId = 10;
    this.myForm = this.formBuilder.group({
      center: new FormControl(null),
      DiagnosticName: ['', Validators.required],
      DiagnosticDisplayName: ['', Validators.required],
      Address: ['', Validators.required],
      OwnerName: ['', Validators.required],
      OwnerEmailId: ['', Validators.required],
      DiagCentreEmailId: ['', Validators.required],
      CentreRegistrationNumber: ['', Validators.required],
      AccountNumber: ['', Validators.required],
      IFSC: ['', Validators.required],
      GSTNumber: ['', Validators.required],
      BankName: ['', Validators.required],
      PANNumber: ['', Validators.required],
      AreaPincode: ['', Validators.required],
      CentreType: ['', Validators.required],
      OwnerPhone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      CentrePhone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    });
    this.registerDiagno();
  }
  get center(): string {
    return this.myForm ? this.myForm.get('center').value : '';
  }

  registerDiagno() {
    this._dignoRegister.getDiagnoId(this.diagnoRegister)
      .subscribe(data => {
        if (data.success) {
          this.diagnoRegister = data.data[0][0];
        } else {
          console.log('error: ' + data.message);
        }
      });
  }

  diagnoSave() {
    this._dignoRegister.DiagnoRegis(this.diagnoRegister)
      .subscribe(data => {
        if (data.success) {
          this.registerDiagno();
          this.toastr.success('Successfully ' + 'Updated Diagnostics!', 'Success');
        } else {
          this.toastr.error(data.message, 'Opps!');
        }
      });

  }

}


import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({
  name: 'searchByDate'
})
export class SearchByDatePipe implements PipeTransform {

  transform(array: any[],  fromDate: any, toDate: any): any {
    if (fromDate && toDate) {
        return _.filter(array, row=>(new Date(row.BookingDate).getTime()>=fromDate.getTime() && new Date(row.BookingDate).getTime()<=toDate.getTime()));
    }
    return array;
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import {DataTableModule} from 'angular2-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RideRequestedRoutingModule } from './ride-requested-routing.module';
import { RideRequestedComponent } from './ride-requested.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,ReactiveFormsModule,
    DataTableModule,
    NgxDatatableModule,
    Ng2SmartTableModule,
    NgxSpinnerModule,
    RideRequestedRoutingModule
  ],
  declarations: [RideRequestedComponent]
})
export class RideRequestedModule { }

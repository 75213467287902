import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import {DataTableModule} from 'angular2-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CurrentRidesRoutingModule } from './current-rides-routing.module';
import { CurrentRidesComponent  } from './current-rides.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,ReactiveFormsModule,
    DataTableModule,
    NgxDatatableModule,
    Ng2SmartTableModule,
    NgxSpinnerModule,
    CurrentRidesRoutingModule
  ],
  declarations: [CurrentRidesComponent]
})
export class CurrentRidesModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateUserService {
  // private createUserUrl = 'http://localhost:3006/auth/createUserLogin';
  private createSignUpUrl = 'http://localhost:3006/user/AddUpdateProfile';
  private otpVerifyUrl = 'http://localhost:3006/user/verifyotp';
  private getuserUrl = 'http://localhost:3006/user/GetuserDetails';

  constructor(private http: HttpClient) { }
  private getHttpOptions() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': JSON.parse(localStorage.getItem('id_token'))
      })
    };
    return httpOptions;
  }

  createSignUp(obj): Observable<any> {
    return this.http.post<any>(this.createSignUpUrl, obj, this.getHttpOptions());
  }
  OptVerify(obj): Observable<any> {
    return this.http.post<any>(this.otpVerifyUrl, obj, this.getHttpOptions());
  }

  getUserdetails(obj): Observable<any> {
    return this.http.post<any>(this.getuserUrl, obj, this.getHttpOptions());
  }

}

import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, NgForm, FormGroupDirective } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { ModalPopupService } from 'src/app/shared/modal-popup/modal-popup.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import swal from 'sweetalert2';
import { isNumber, toInteger, padNumber } from '@ng-bootstrap/ng-bootstrap/util/util';
import { DiagnoRegisterService } from 'src/app/Service/diagno-register.service';
import { NgxSpinnerService } from 'ngx-spinner';


import {
  NgbDatepicker,
  NgbInputDatepicker,
  NgbDateStruct,
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from "@angular/forms";

import { Subscription } from 'rxjs';
import { NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-adapter';
import { copyStyles } from '@angular/animations/browser/src/util';
import { environment } from 'src/environments/environment';

const DATA_API_URL = environment.baseUrl;
const now = new Date();
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one && two && two.year === one.year && two.month === one.month && two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day < two.day : one.month < two.month : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
    ? false : one.day > two.day : one.month > two.month : one.year > two.year;

const URL = DATA_API_URL + '/UploadDiagReport';

@Component({
  selector: 'app-diagnostics-booking',
  templateUrl: './diagnostics-booking.component.html',
  styleUrls: ['./diagnostics-booking.component.scss']
})
export class DiagnosticsBookingComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;


  public data: any;
  public rowsOnPage = 10;
  public filterQuery = '';
  public statusFiltervalue: number = 0;
  public sortBy = '';
  public sortOrder = 'desc';
  public searchNameString: string;
  public uploader: FileUploader;
  response: string;

  patientPhoneNumber:string;
  patientEmail:string;
  patientAddress: string;

  //For test result pop up
  rows = [];
  expanded = {};
  timeout: any;
  rowsFilter = [];
  tempFilter = [];
  fileToUpload: File = null;

  PhebloDetails: FormGroup;
  TestDetails: FormGroup;
  BookingStatusMaster: any[];
  public rowBookingId: number;
  public ResultId: number;
  public ServiceId: number;
  

  @Input('modalDefault') modalDefault: any;

  //below code for date
  startDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;
  hoveredDate: NgbDateStruct;
  fromDate: any;
  toDate: any;
  filterFromDate: any;
  filterToDate: any;
  model: any;
  private _subscription: Subscription;
  private _selectSubscription: Subscription;
  @ViewChild("d") input: NgbInputDatepicker;
  @ViewChild(NgModel) datePick: NgModel;
  @ViewChild('myRangeInput') myRangeInput: ElementRef;

  isHovered = date =>
    this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate)
  isInside = date => after(date, this.fromDate) && before(date, this.toDate);
  isFrom = date => equals(date, this.fromDate);
  isTo = date => equals(date, this.toDate);
  isValidDate: any;

  constructor(private formBuilder: FormBuilder
    , private diagnoRegisterService: DiagnoRegisterService
    , private modalPopupService: ModalPopupService
    , private toastr: ToastrService
    , element: ElementRef, private renderer: Renderer2, private _parserFormatter: NgbDateParserFormatter
    , private spinner: NgxSpinnerService
    
  ) {
     this.spinner.show();
      this.diagnoRegisterService.getBookingStatusMaster()
      .subscribe(data => this.BookingStatusMaster = data.data[0]);

    this.diagnoRegisterService.getDiagCentreBookingDetails()
      .subscribe((data) => {
        console.log(data);
        this.data = data.data[0];
      },
      err=>{console.log(err)}
      ,() => this.spinner.hide()
      );

    this.uploader = new FileUploader({
      url: URL
    });

    this.response = '';
    this.uploader.response
      .subscribe(res => {
        this.response = res;
        let resJson: any = JSON.parse(res);
        console.log(this.response);
        if (resJson.originalname === this.uploader.queue[0].file.name) {
          this.toastr.success('Successfully ' + 'file uploaded!', 'Success');
          //update file name in DB
          this.UploadTestResult(this.uploader.queue[0].file.name);
        } else {
          this.toastr.error(this.response, 'Opps!');
        }
      },
        err => console.log(err),
        () => { console.log("file uploaded.....") }
      );
  }

  allowOne() {
    this.uploader.clearQueue();
  }

  UploadTestResult(filename: string) {
    let obj = {
      "filename": filename,
      "ResultId": this.ResultId
    }

    console.log(obj);

    this.diagnoRegisterService.UploadTestResult(obj)
      .subscribe(data => {
        if (data.message === "success") {
          this.toastr.success('Successfully ' + 'Result uploaded!', 'Success');
          this.allowOne();
          this.closeModal('custom-modal-2');
        }
        else {
          this.toastr.error(data.message, 'Opps!');
        }
      });
  }

  ngOnInit() {
    this.spinner.show();
    this.startDate = { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
    this.maxDate = { year: now.getFullYear() + 1, month: now.getMonth() + 1, day: now.getDate() };
    this.minDate = { year: now.getFullYear() - 1, month: now.getMonth() + 1, day: now.getDate() };

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    }



    this.PhebloDetails = this.formBuilder.group({
      PhebloName: ['', Validators.required],
      PhebloContact: ['', Validators.required],
      InstructionByCentre: ['', Validators.required],
    });

    this.TestDetails = this.formBuilder.group({
      TestName: ['', Validators.required],
      Rate: ['', Validators.required],
      Result_path: ['', Validators.required],
      Result_Date: ['', Validators.required],
      Result_remarks: ['', Validators.required]
    });
  }

  get fPhebloDetails() { return this.PhebloDetails.controls; }
  get fTestDetails() { return this.TestDetails.controls; }

  openConfirmsSwal(event, oldVal: any, bookingId: number) {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to change the Booking status',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!'
    }).then((result) => {
      if (result.value) {
        this.UpdateBookingStatus(event, bookingId)
      }
      else {
        event.target.value = oldVal;
      }
    });
  }
  UpdateBookingStatus(event, bookingId: number) {

    let obj = {
      "BookingId": bookingId,
      "BookingStatusId": event.target.value
    }
    console.log(obj);
    this.diagnoRegisterService.updateBookingStatus(obj)
      .subscribe(data => {
        if (data.success) {
           //Send notification
           let notificationObj = {
            "BookingId": this.rowBookingId,
            "Event":"Booking status",
            "Message":"Booking status updated!"
          }

          this.diagnoRegisterService.SendDiagNotification(notificationObj)
          .subscribe(data => console.log("notification sent!"));

          swal(
            'Updated!',
            'Booking status has been updated.',
            'success'
          );
        } else {
          this.toastr.error(data.message, 'Opps!');
        }
      });
  }

  openMyModalData(event) {
    document.querySelector('#' + event).classList.add('md-show');
  }

  openPatientDetail(event,phoneNumber:string,email:string,address:string){
    this.patientPhoneNumber = phoneNumber;
    this.patientEmail = email;
    this.patientAddress = address;

    document.querySelector('#' + event).classList.add('md-show');
  }

  openPhebloModal(event, bookingId: number) {

    this.rowBookingId = bookingId;
    console.log(this.rowBookingId);
    let obj = {
      "BookingId": this.rowBookingId,
      "IsSet": 0
    };

    this.diagnoRegisterService.getSetPhebloDetailsDetails(obj)
      .subscribe((data) => {
        if (data.data) {
          this.PhebloDetails.controls["PhebloName"].setValue(data.data[0][0].PhebloName);
          this.PhebloDetails.controls["PhebloContact"].setValue(data.data[0][0].PhebloContact);
          this.PhebloDetails.controls["InstructionByCentre"].setValue(data.data[0][0].InstructionByCentre);
        }
      });
    document.querySelector('#' + event).classList.add('md-show');
  }

  closeMyModal(event) {
    ((event.target.parentElement.parentElement.parentElement).parentElement).classList.remove('md-show');
  }

  updatePhebloDetails(event) {

    let obj = {
      "BookingId": this.rowBookingId,
      "IsSet": 1,
      "PhebloName": this.PhebloDetails.controls["PhebloName"].value,
      "PhebloContact": this.PhebloDetails.controls["PhebloContact"].value,
      "InstructionByCentre": this.PhebloDetails.controls["InstructionByCentre"].value
    }

    this.diagnoRegisterService.getSetPhebloDetailsDetails(obj)
      .subscribe(data => {
        if (data.success) {
          //Send notification
          let notificationObj = {
            "BookingId": this.rowBookingId,
            "Event":"Pheblo",
            "Message":"Pheblo has been assigned to collect sample."
          }

          this.diagnoRegisterService.SendDiagNotification(notificationObj).subscribe(data => console.log(data));

          this.toastr.success('Successfully ' + 'Pheblo details updated!', 'Success');
        } else {
          this.toastr.error(data.message, 'Opps!');
        }
      });
    ((event.target.parentElement.parentElement.parentElement).parentElement).classList.remove('md-show');
  }

  openTestResultUploadModal(event,row) {
    console.log(row);
    this.ResultId = row.ResultId;
    this.openModal('custom-modal-2');
  }

  openTestDetailModal(event, bookingId: number) {

    this.rowBookingId = bookingId;
    console.log(this.rowBookingId);
    let obj = {
      "BookingId": this.rowBookingId,
      "IsSet": 0,
    };

    let response;
    let date: NgbDateStruct;

    this.rowsFilter = [];
    this.spinner.show();
    this.diagnoRegisterService.getTestDetailbyCentre(obj)
      .subscribe((data) => {
        this.spinner.hide();
        console.log(data);
        if (data.success == true) {
          response = data.data[0];
          response.map(x => x.Result_Date = x.Result_Date === null ? x.Result_Date :
            {
              day: toInteger(x.Result_Date.substr(8, 2))
              , month: toInteger(x.Result_Date.substr(5, 2))
              , year: toInteger(x.Result_Date.substr(0, 4))
            }
          );
          console.log(response);
          // cache our list
          this.tempFilter = [...response];

          // push our inital complete list
          this.rowsFilter = response;
        }
      });
    this.openModal('custom-modal-1');
    //document.querySelector('#' + event).classList.add('md-show');
  }

  updateTestDetail(ObjServiceData: any) {
    let resultDate;
    if (ObjServiceData.Result_Date != null) {
      resultDate = ObjServiceData.Result_Date.year + '-' + ObjServiceData.Result_Date.month + '-' + ObjServiceData.Result_Date.day;
    }
    let obj = {
      "BookingId": this.rowBookingId,
      "ServiceId": ObjServiceData.ServiceId,
      "Result_path": ObjServiceData.Result_path,
      "Result_Date": resultDate,
      "Result_remarks": ObjServiceData.Result_remarks
    }

    console.log(obj);
    this.diagnoRegisterService.updateTestDetailbyCentre(obj)
      .subscribe(data => {
        if (data.success) {
          //Send notification
          let notificationObj = {
            "BookingId": this.rowBookingId,
            "Event":"Test Result",
            "Message":"Result date/Report has been updated of your test."
          }

          this.diagnoRegisterService.SendDiagNotification(notificationObj)
          .subscribe(data => console.log("notification sent!"));

          this.toastr.success('Successfully ' + 'Test details updated!', 'Success');
        } else {
          this.toastr.error(data.message, 'Opps!');
        }
      });
    // ((event.target.parentElement.parentElement.parentElement).parentElement).classList.remove('md-show');
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);// element.target.files;
  }

  setStatusFilter(statusId: number) {
    this.statusFiltervalue = statusId;
  }

  openModal(id: string) {
    this.modalPopupService.open(id);
  }

  closeModal(id: string) {
    this.modalPopupService.close(id);
  }

  onDateSelection(date: NgbDateStruct) {
    // let date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day)
    let parsed = '';
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.filterFromDate = new Date(date.year, date.month - 1, date.day);
    } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
      this.toDate = date;
      this.filterToDate = new Date(date.year, date.month - 1, date.day)
      // this.model = `${this.fromDate.year} - ${this.toDate.year}`;
      this.input.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
      this.filterFromDate = new Date(date.year, date.month - 1, date.day);
    }
    if (this.fromDate) {
      parsed += this._parserFormatter.format(this.fromDate);
    }
    if (this.toDate) {
      parsed += ' - ' + this._parserFormatter.format(this.toDate);
    }

    this.renderer.setProperty(this.myRangeInput.nativeElement, 'value', parsed);

  }

  // validateDates(sDate: string, eDate: string){
  //   this.isValidDate = true;
  //   if((sDate == null || eDate ==null)){
  //     this.error={isError:true,errorMessage:'Start date and end date are required.'};
  //     this.isValidDate = false;
  //   }

  //   if((sDate != null && eDate !=null) && (eDate) < (sDate)){
  //     this.error={isError:true,errorMessage:'End date should be grater then start date.'};
  //     this.isValidDate = false;
  //   }
  //   return this.isValidDate;
  // }

}

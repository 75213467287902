import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'sub',
        icon: 'ti-home',
        children: [
          {
            state: 'default',
            name: 'Home'
          }
        ]
      },
    ],
  },
  {
    label: 'Diagnostics',
    main: [
      {
        state: 'register new diagnostics',
        short_label: 'D',
        name: 'Centre Details',
        type: 'sub',
        icon: 'ti-layout-grid2-alt',
        children:
          [
            // {
            //   state: 'events-calendar',
            //   name: 'Schedule'
            // },

            {
              state: 'diagnostic-profile',
              name: 'Profile'
            },
            {
              state: 'diagnostic-service-ratecard',
              name: 'Service Rate Card'
            },
            // {
            //   state: 'diagnostic-registration',
            //   name: 'Registration'
            // },
            {
              state: 'diagnostics-booking',
              name: 'Booking'
            },
            {
              state: 'diagnostics-centre-payment',
              name: 'Centre Payment'
            },
          ]
      },
    ]
  },
  {
    label: 'Members',
    main: [
      {
        state: 'list',
        short_label: 'F',
        main_state: 'member',
        name: 'Member List',
        type: 'link',
        icon: 'ti-user',
      },
      {
        state: 'registration',
        short_label: 'P',
        main_state: 'member',
        name: 'New Member',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },
  {
    label: 'Company',
    main: [
      {
        state: 'list',
        short_label: 'CL',
        main_state: 'company',
        name: 'Company List',
        type: 'link',
        icon: 'ti-user',
      },
      {
        state: 'registration',
        short_label: 'CN',
        main_state: 'company',
        name: 'New Company',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },  
  {
    label: 'Lead booking',
    main: [
      {
        state: 'registration',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'New Booking',
        type: 'link',
        icon: 'ti-plus',
      },
      {
        state: 'googleMap',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'map location search',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'search',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Booking search',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'report',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Report',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'audit-report',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Audit Report - Trips',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'top-view',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Top View',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'drives',
        short_lable: 'CN',
        main_state: 'lead-booking',
        name: 'Dues Report',
        type: 'link',
        icon: 'ti-search'
      },
      {
        state: 'driver-payment',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Driver Payment',
        type: 'link',
        icon: 'ti-search',
      }
    ]
  },

];

const MENUITEMSForDiagnostics = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'sub',
        icon: 'ti-home',
        children: [
          {
            state: 'default',
            name: 'Home'
          }
        ]
      },
    ],
  },
  {
    label: 'Diagnostics',
    main: [
      {
        state: 'register new diagnostics',
        short_label: 'D',
        name: 'Centre Details',
        type: 'sub',
        icon: 'ti-layout-grid2-alt',
        children:
          [
            // {
            //   state: 'events-calendar',
            //   name: 'Schedule'
            // },

            {
              state: 'diagnostic-profile',
              name: 'Profile'
            },
            {
              state: 'diagnostic-service-ratecard',
              name: 'Service Rate Card'
            },
            // {
            //   state: 'diagnostic-registration',
            //   name: 'Registration'
            // },
            {
              state: 'diagnostics-booking',
              name: 'Booking'
            },
            {
              state: 'diagnostics-centre-payment',
              name: 'Centre Payment'
            },
            {
              state: 'diagnostic-registrations',
              name: 'New Diagnostic'
            },
          ]
      },
    ]
  },
  {
    label: 'Pharmacy',
    main: [
      {
        state: 'pharmacy-booking',
        short_label: 'D',
        name: 'Orders',
        type: 'sub',
        icon: 'ti-layout-grid2-alt',
        children:
          [
            {
              state: 'ListOrders',
              name: 'List Orders'
            },
            {
              state: 'New-Order',
              name: 'New Pharmacy Order'
            },
          ]
      },
    ]
  },
  {
    label: 'Members',
    main: [
      {
        state: 'list',
        short_label: 'F',
        main_state: 'member',
        name: 'Member List',
        type: 'link',
        icon: 'ti-user',
      },
      {
        state: 'registration',
        short_label: 'P',
        main_state: 'member',
        name: 'New Member',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },
];

const MENUITEMSForCaller = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'sub',
        icon: 'ti-home',
        children: [
          {
            state: 'caller',
            name: 'Home'
          }
        ]
      },
    ],
  },
  {
    label: 'Members',
    main: [
      {
        state: 'list',
        short_label: 'F',
        main_state: 'member',
        name: 'Member List',
        type: 'link',
        icon: 'ti-user',
      },
      {
        state: 'registration',
        short_label: 'P',
        main_state: 'member',
        name: 'New Member',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },
  {
    label: 'Company',
    main: [
      {
        state: 'registration',
        short_label: 'CN',
        main_state: 'company',
        name: 'New Company',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },
  {
    label: 'Lead booking',
    main: [
      {
        state: 'registration',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'New Booking',
        type: 'link',
        icon: 'ti-plus',
      },
      {
        state: 'googleMap',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'map location search',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'search',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Booking search',
        type: 'link',
        icon: 'ti-search',
      }
      ,
      {
        state: 'audit-report',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Audit Report - Trips',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'report',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Report',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'top-view',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Top View',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'drives',
        short_lable: 'CN',
        main_state: 'lead-booking',
        name: 'Dues Report',
        type: 'link',
        icon: 'ti-search'
      }
    ]
  },
  {
    label: 'Paramedic booking',
    main: [
      {
        state: 'registration',
        short_label: 'PR',
        main_state: 'paramedic-booking',
        name: 'New Patient',
        type: 'link',
        icon: 'ti-plus',
      },
      {
        state: 'search',
        short_label: 'PS',
        main_state: 'paramedic-booking',
        name: 'Patient List',
        type: 'link',
        icon: 'ti-search',
      }
    ]
  },

];

const MENUITEMSForLimitedCaller = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'sub',
        icon: 'ti-home',
        children: [
          {
            state: 'caller',
            name: 'Home'
          }
        ]
      },
    ],
  },
  {
    label: 'Members',
    main: [
      {
        state: 'list',
        short_label: 'F',
        main_state: 'member',
        name: 'Member List',
        type: 'link',
        icon: 'ti-user',
      },
      {
        state: 'registration',
        short_label: 'P',
        main_state: 'member',
        name: 'New Member',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },
  {
    label: 'Company',
    main: [
      {
        state: 'registration',
        short_label: 'CN',
        main_state: 'company',
        name: 'New Company',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },
  {
    label: 'Lead booking',
    main: [
      {
        state: 'registration',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'New Booking',
        type: 'link',
        icon: 'ti-plus',
      },
      {
        state: 'googleMap',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'map location search',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'search',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Booking search',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'audit-report',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Audit Report - Trips',
        type: 'link',
        icon: 'ti-search',
      }
    ]
  },
];

const MENUITEMSForHospitalCaller = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'sub',
        icon: 'ti-home',
        children: [
          {
            state: 'caller',
            name: 'Home'
          }
        ]
      },
    ],
  },
  {
    label: 'Members',
    main: [
      {
        state: 'list',
        short_label: 'F',
        main_state: 'member',
        name: 'Member List',
        type: 'link',
        icon: 'ti-user',
      },
      {
        state: 'registration',
        short_label: 'P',
        main_state: 'member',
        name: 'New Member',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },
  {
    label: 'Lead booking',
    main: [
      {
        state: 'registration',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'New Booking',
        type: 'link',
        icon: 'ti-plus',
      },
      {
        state: 'googleMap',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'map location search',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'search',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Booking search',
        type: 'link',
        icon: 'ti-search',
      }
    ]
  },
  {
    label: 'Completed Reports',
    main: [      
      {
        state: 'report',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Report',
        type: 'link',
        icon: 'ti-search',
      },
    ]
  },
];


const MENUITEMSForAdmins = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'sub',
        icon: 'ti-home',
        children: [
          {
            state: 'caller',
            name: 'Home'
          }
        ]
      },
    ],
  },
  {
    label: 'Diagnostics',
    main: [
      {
        state: 'register new diagnostics',
        short_label: 'D',
        name: 'Centre Details',
        type: 'sub',
        icon: 'ti-layout-grid2-alt',
        children: 
        [
          // {
          //   state: 'events-calendar',
          //   name: 'Schedule'
          // },
          
          {
            state: 'diagnostic-profile',
            name: 'Profile'
          },
          {
            state: 'diagnostic-service-ratecard',
            name: 'Service Rate Card'
          },
          // {
          //   state: 'diagnostic-registration',
          //   name: 'Registration'
          // },
          {
            state: 'diagnostics-booking',
            name: 'Booking'
          },
          {
            state: 'diagnostics-centre-payment',
            name: 'Centre Payment'
          },
          {
            state: 'diagnostic-registrations',
            name: 'New Diagnostic'
          },
        ]
      },
    ]
  },
  {
    label: 'Accounts',
    main: [
      {
        state: 'approval-list',
        short_label: 'F',
        main_state: 'accounts',
        name: 'Pending List',
        type: 'link',
        icon: 'ti-user',
      }
    ]
  },
  {
    label: 'Members',
    main: [
      {
        state: 'list',
        short_label: 'F',
        main_state: 'member',
        name: 'Member List',
        type: 'link',
        icon: 'ti-user',
      },
      {
        state: 'registration',
        short_label: 'P',
        main_state: 'member',
        name: 'New Member',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },
  {
    label: 'Company',
    main: [
      {
        state: 'list',
        short_label: 'CL',
        main_state: 'company',
        name: 'Company List',
        type: 'link',
        icon: 'ti-user',
      },
      {
        state: 'registration',
        short_label: 'CN',
        main_state: 'company',
        name: 'New Company',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },
  {
    label: 'Vehicle',
    main: [
      {
        state: 'vehicle-list',
        short_label: 'CL',
        main_state: 'vehicle',
        name: 'Vehicle List',
        type: 'link',
        icon: 'ti-user',
      },
      {
        state: 'vehicle-registration',
        short_label: 'VR',
        main_state: 'vehicle',
        name: 'New Vehicle',
        type: 'link',
        icon: 'ti-plus',
      }
    ]
  },
  {
    label: 'Completed Reports',
    main: [
      {
        state: 'audit-report',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Aggregated Vehicles',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'audit-report-han',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Leased Vehicles',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'train-report-han',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Train Trips',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'air-report-han',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Air Trips',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'report',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Report',
        type: 'link',
        icon: 'ti-search',
      },
    ]
  },
  {
    label: 'Lead booking',
    main: [
      {
        state: 'registration',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'New Booking',
        type: 'link',
        icon: 'ti-plus',
      },
      {
        state: 'googleMap',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'map location search',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'search',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Booking search',
        type: 'link',
        icon: 'ti-search',
      },     
      
      {
        state: 'top-view',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Top View',
        type: 'link',
        icon: 'ti-search',
      },
      {
        state: 'drives',
        short_lable: 'CN',
        main_state: 'lead-booking',
        name: 'Dues Report',
        type: 'link',
        icon: 'ti-search'
      },
      {
        state: 'driver-payment',
        short_label: 'CN',
        main_state: 'lead-booking',
        name: 'Driver Payment',
        type: 'link',
        icon: 'ti-search',
      }
    ]
  },
  {
    label: 'Paramedic booking',
    main: [
      {
        state: 'registration',
        short_label: 'PR',
        main_state: 'paramedic-booking',
        name: 'New Patient',
        type: 'link',
        icon: 'ti-plus',
      },
      {
        state: 'search',
        short_label: 'PS',
        main_state: 'paramedic-booking',
        name: 'Patient List',
        type: 'link',
        icon: 'ti-search',
      }
    ]
  },

];

const MENUITEMSForParamedic = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        short_label: 'D',
        name: 'Dashboard',
        type: 'sub',
        icon: 'ti-home',
        children: [
          {
            state: 'paramedic',
            name: 'Home'
          }
        ]
      },
    ],
  },
  {
    label: 'Paramedic booking',
    main: [
      {
        state: 'registration',
        short_label: 'PR',
        main_state: 'paramedic-booking',
        name: 'New Patient',
        type: 'link',
        icon: 'ti-plus',
      },
      {
        state: 'search',
        short_label: 'PS',
        main_state: 'paramedic-booking',
        name: 'Patient List',
        type: 'link',
        icon: 'ti-search',
      }
    ]
  },

];


@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
  getMenuforDiagnostics(): Menu[] {
    return MENUITEMSForDiagnostics;
  }
  getMenuforCaller(): Menu[] {
    return MENUITEMSForCaller;
  }
  getMenuforParamedic(): Menu[] {
    return MENUITEMSForParamedic;
  }
  getMenuforAdmins(): Menu[] {
    return MENUITEMSForAdmins;
  }
  getMenuforLimitedCaller(): Menu[] {
    return MENUITEMSForLimitedCaller;
  }
  getMenuforHospitalCaller(): Menu[] {
    return MENUITEMSForHospitalCaller;
  }
}

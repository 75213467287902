import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {RideRequestedComponent } from './ride-requested.component';


const routes: Routes = [{
  path: '',
  component: RideRequestedComponent,
  data: {
    title: 'Ride Requested'
  }
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RideRequestedRoutingModule { }

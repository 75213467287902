import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({
  name: 'searchByStatus'
})
export class SearchByStatusPipe implements PipeTransform {

  transform(array: any[], StatusId:number): any {
    console.log(StatusId);

    if (StatusId!=0) {
        return _.filter(array, row=>row.BookingStatusId === StatusId);
    }
    return array;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {UiSwitchModule} from 'ng2-ui-switch';
import { DiagnosticProfileRoutingModule } from './diagnostic-profile-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { WizardBasicComponent } from 'src/app/theme/forms/form-wizards/wizard-basic/wizard-basic.component';
import {ArchwizardModule} from 'ng2-archwizard/dist';
import { DiagnosticProfileComponent } from './diagnostic-profile.component';
import { FileUploadModule } from "ng2-file-upload"; 
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    DiagnosticProfileRoutingModule,
    SharedModule,ArchwizardModule,
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule,
    FileUploadModule,
    NgxSpinnerModule
  ],
  declarations: [WizardBasicComponent,DiagnosticProfileComponent],
  
})
export class DiagnosticProfileModule { }

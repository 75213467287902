import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DiagnosticProfileComponent } from './diagnostic-profile.component';

const routes: Routes = [{
  path: '',
  component: DiagnosticProfileComponent,
  data: {
    title: 'Diagnostic Profile'
  }
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DiagnosticProfileRoutingModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import { DiagnosticsBookingRoutingModule } from './diagnostics-booking-routing.module';
import { DiagnosticsBookingComponent } from './diagnostics-booking.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { SharedModule } from 'src/app/shared/shared.module';
import {DataTableModule} from 'angular2-datatable';
import { SearchByNamePipe } from './search-by-name.pipe';
import { SearchByStatusPipe } from './search-by-status.pipe';
import {SearchByDatePipe} from './search-by-date.pipe';
import { ModalPopupModule } from 'src/app/shared/modal-popup/modal-popup.module';
import { FileUploadModule } from "ng2-file-upload"; 
import { NgbDateFRParserFormatter } from 'src/app/shared/date/ngb-date-fr-parser-formatter';
import {
  NgbDropdownModule,
  NgbModule,
  NgbPopover,
  NgbTabsetModule,
  NgbDatepicker,
  NgbDatepickerModule,
  NgbDatepickerConfig,
  NgbDateParserFormatter,
  NgbDateAdapter
} from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  imports: [
    CommonModule,
    DiagnosticsBookingRoutingModule,
    SharedModule,
    FormsModule,ReactiveFormsModule,
    DataTableModule,
    HttpModule,
    NgxDatatableModule,
    Ng2SmartTableModule,
    ModalPopupModule,
    FileUploadModule,
    NgxSpinnerModule
  ],
 // exports: [SpinnerComponent],
  declarations: [DiagnosticsBookingComponent, SearchByNamePipe, SearchByStatusPipe, SearchByDatePipe
  ],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateFRParserFormatter
    }
  ]
})

export class DiagnosticsBookingModule { }

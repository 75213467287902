﻿import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalPopupService {
    private modalPopups: any[] = [];

    add(modalPopup: any) {
        // add modal to array of active modals
        this.modalPopups.push(modalPopup);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modalPopups = this.modalPopups.filter(x => x.id !== id);
    }

    open(id: string) {
        // open modal specified by id
        const modalPopup = this.modalPopups.find(x => x.id === id);
        modalPopup.open();
    }

    close(id: string) {
        // close modal specified by id
        const modalPopup = this.modalPopups.find(x => x.id === id);
        modalPopup.close();
    }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'auth/login/simple',
        pathMatch: 'full'
      },
      {
        path: 'auth',
        loadChildren: './theme/auth/auth.module#AuthModule'
      },
      {
        path: 'email/email-template',
        loadChildren: './theme/email/email-template/email-template.module#EmailTemplateModule'
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren: './theme/maintenance/offline-ui/offline-ui.module#OfflineUiModule'
      },
      {
        path: 'landing',
        loadChildren: './theme/landing/landing.module#LandingModule'
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard/default',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: './theme/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'navigation',
        loadChildren: './theme/navigation/navigation.module#NavigationModule'
      },
      {
        path: 'widget',
        loadChildren: './theme/widget/widget.module#WidgetModule'
      },
      {
        path: 'basic',
        loadChildren: './theme/ui-elements/basic/basic.module#BasicModule'
      },
      {
        path: 'advance',
        loadChildren: './theme/ui-elements/advance/advance.module#AdvanceModule'
      },
      {
        path: 'animations',
        loadChildren: './theme/ui-elements/animation/animation.module#AnimationModule'
      },
      {
        path: 'forms',
        loadChildren: './theme/forms/forms.module#FormsModule'
      },
      {
        path: 'bootstrap-table',
        loadChildren: './theme/table/bootstrap-table/bootstrap-table.module#BootstrapTableModule'
      },
      {
        path: 'data-table',
        loadChildren: './theme/table/data-table/data-table.module#DataTableModule'
      },
      {
        path: 'maintenance/error',
        loadChildren: './theme/maintenance/error/error.module#ErrorModule'
      },
      {
        path: 'maintenance/coming-soon',
        loadChildren: './theme/maintenance/coming-soon/coming-soon.module#ComingSoonModule'
      },
      {
        path: 'user',
        loadChildren: './theme/user/user.module#UserModule'
      },
      {
        path: 'email',
        loadChildren: './theme/email/email.module#EmailModule'
      },
      {
        path: 'task',
        loadChildren: './theme/task/task.module#TaskModule'
      },
      {
        path: 'crm-contact',
        loadChildren: './theme/crm-contact/crm-contact.module#CrmContactModule'
      },
      {
        path: 'invoice',
        loadChildren: './theme/extension/invoice/invoice.module#InvoiceModule'
      },
      {
        path: 'file-upload-ui',
        loadChildren: './theme/extension/file-upload-ui/file-upload-ui.module#FileUploadUiModule'
      },
      {
        path: 'calendar',
        loadChildren: './theme/extension/event-calendar/event-calendar.module#EventCalendarModule'
      },
      {
        path: 'charts',
        loadChildren: './theme/chart/chart.module#ChartModule'
      },
      {
        path: 'map',
        loadChildren: './theme/map/map.module#MapModule'
      },
      {
        path: 'simple-page',
        loadChildren: './theme/simple-page/simple-page.module#SimplePageModule'
      },
      {
        path: 'Trip/trip-history',
        loadChildren: './Trip/trip-history/trip-history.module#TripHistoryModule'
      },
      {
        path: 'Billing/bill-history',
        loadChildren: './Billing/bill-history/bill-history.module#BillHistoryModule'
      },
      {
        path: 'register new diagnostics/events-calendar',
        loadChildren: './theme/extension/event-calendar/event-calendar.module#EventCalendarModule'
      },
      {
        path: 'register new diagnostics/diagnostic-profile',
        loadChildren: './Diagnostics/diagnostic-profile/diagnostic-profile.module#DiagnosticProfileModule'
      },
      {
        path: 'register new diagnostics/diagnostic-service-ratecard',
        loadChildren: './Diagnostics/diag-service-ratecard/diag-service-ratecard.module#DiagServiceRatecardModule'
      },
      {
        path: 'register new diagnostics/diagnostic-registration',
        loadChildren: './Diagnostics/diagnostic-registration/diagnostic-registration.module#DiagnosticRegistrationModule'
      },
      {
        path: 'register new diagnostics/diagnostic-registrations',
        loadChildren: './Diagnostics/diagnostic-registrations/diagnostic-registrations.module#DiagnosticRegistrationsModule'
      },
      {
        path: 'register new diagnostics/diagnostics-booking',
        loadChildren: './Diagnostics/diagnostics-booking/diagnostics-booking.module#DiagnosticsBookingModule'
      },
      {
        path: 'register new diagnostics/diagnostics-centre-payment',
        loadChildren: './Diagnostics/diagnostic-centre-payment/diagnostic-centre-payment.module#DiagnosticCentrePaymentModule'
      },
      {
        path: 'register new vehicle/vehicle-register',
        loadChildren: './Vehicle/vehicle-register/vehicle-register.module#VehicleRegisterModule'
      }
      //below to be extracted for call centre
      ,
      {
        path: 'ride detail/ride-requested',
        loadChildren: './Ambulance/RideRequested/ride-requested.module#RideRequestedModule'
      },
      {
        path: 'ride detail/current-rides',
        loadChildren: './Ambulance/CurrentRides/current-rides.module#CurrentRidesModule'
      },
      {
        path: 'ride detail/ride-history',
        loadChildren: './Ambulance/RideHistory/ride-history.module#RideHistoryModule'
      },
      {
        path: 'member',
        loadChildren: './Member/member.module#MemberModule'
      },
      {
        path: 'company',
        loadChildren: './Company/company.module#CompanyModule'
      },
      {
        path: 'vehicle',
        loadChildren: './Vehicle/vehicle.module#VehicleModule'
      },
      {
        path: 'accounts',
        loadChildren: './account/account.module#AccountModule'
      },
      {
        path: 'lead-booking',
        loadChildren: './LeadBooking/leadbooking.module#LeadBookingModule'
      },
      {
        path: 'paramedic-booking',
        loadChildren: './ParamedicBooking/paramedicbooking.module#ParamedicBookingModule',
      },
      {
        path: 'pharmacy-booking',
        loadChildren: './Pharmacy/pharmacy.module#PharmacyModule',
      },
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //onSameUrlNavigation: 'ignore',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
